<script setup lang="ts">
    import 'element-plus/es/components/avatar/style/index';
    import {ElAvatar} from 'element-plus';

    import JetIcon from './JetIcon.vue';

    const props = defineProps({
        size: {
            type: String,
            default: 'large',
        },
        isIcon: {
            type: Boolean,
            default: false,
        },
        iconStyle: {
            type: String,
            default: 'regular',
        },
        iconName: {
            type: String,
            default: '',
        },
        iconColor: {
            type: String,
            default: '',
        },
        iconBgColor: {
            type: String,
            default: '#ffffff',
        },
        pointer: {
            type: Boolean,
            default: false,
        },
    });
    const getElementPlusSize = computed(() => {
        return {
            'extra-large': '',
            large: 'large',
            default: 'default',
            small: 'small',
            'extra-small': '',
        }[props.size];
    });

    const avatar = ref(null);
    const hasSrcProp = computed(() => {
        return Boolean(avatar.value?.src);
    });

    const colors = ['blue', 'purple', 'lime', 'brown', 'violet', 'green', 'primary', ''];
</script>

<template>
    <ElAvatar
        ref="avatar"
        class="semibold jet-avatar"
        :size="getElementPlusSize"
        :class="{
            'jet-avatar--size-xs': size === 'extra-small',
            'jet-avatar--size-sm': size === 'small',
            'jet-avatar--size-default': size === 'default',
            'jet-avatar--size-lg': size === 'large',
            md: size === 'large',
            xxs: size === 'small' || size === 'extra-small',
            'jet-avatar--size-xl': size === 'extra-large',
            'jet-avatar--image': hasSrcProp,
            'jet-avatar--text': !hasSrcProp,
            'jet-avatar--is-icon': isIcon,
            'jet-avatar--color-blue': isIcon && iconColor === 'blue',
            'jet-avatar--color-purple': isIcon && iconColor === 'purple',
            'jet-avatar--color-lime': isIcon && iconColor === 'lime',
            'jet-avatar--color-brown': isIcon && iconColor === 'brown',
            'jet-avatar--color-violet': isIcon && iconColor === 'violet',
            'jet-avatar--color-green': isIcon && iconColor === 'green',
            'jet-avatar--color-primary': isIcon && iconColor === 'primary',
            'jet-avatar--color-custom': isIcon && !colors.includes(iconColor),
            'jet-avatar--pointer': pointer,
        }">
        <JetIcon v-if="isIcon" :icon-style="iconStyle" :name="iconName" />
        <slot v-else />
    </ElAvatar>
</template>

<style scoped lang="scss">
    .jet-avatar--size-xs {
        width: var(--jet-avatar-size-extra-small);
        min-width: var(--jet-avatar-size-extra-small);
        height: var(--jet-avatar-size-extra-small);
        min-height: var(--jet-avatar-size-extra-small);
    }

    .jet-avatar--size-sm {
        min-width: var(--jet-avatar-size-small);
        min-height: var(--jet-avatar-size-small);
    }

    .jet-avatar--size-default {
        min-width: var(--jet-avatar-size-default);
        min-height: var(--jet-avatar-size-default);
    }

    .jet-avatar--size-lg {
        min-width: var(--jet-avatar-size-large);
        min-height: var(--jet-avatar-size-large);
    }

    .jet-avatar--size-xl {
        width: var(--jet-avatar-size-extra-large);
        min-width: var(--jet-avatar-size-extra-large);
        height: var(--jet-avatar-size-extra-large);
        min-height: var(--jet-avatar-size-extra-large);
    }

    .jet-avatar--image {
        background-color: transparent;
    }

    .jet-avatar--is-icon {
        background-color: var(--el-color-primary-light-9);
        color: var(--el-color-primary);
        border: none;
    }

    .jet-avatar--color-blue {
        color: var(--jet-color-blue-dark-2);
        background-color: var(--jet-color-blue-light-7);
    }

    .jet-avatar--color-purple {
        color: var(--jet-color-purple-dark-2);
        background-color: var(--jet-color-purple-light-7);
    }

    .jet-avatar--color-lime {
        color: var(--jet-color-lime-dark-2);
        background-color: var(--jet-color-lime-light-7);
    }

    .jet-avatar--color-brown {
        color: var(--jet-color-brown-dark-2);
        background-color: var(--jet-color-brown-light-7);
    }

    .jet-avatar--color-violet {
        color: var(--jet-color-violet-dark-2);
        background-color: var(--jet-color-violet-light-7);
    }

    .jet-avatar--color-green {
        color: var(--jet-color-green-dark-2);
        background-color: var(--jet-color-green-light-7);
    }

    .jet-avatar--color-primary {
        color: var(--el-color-primary-dark-2);
        background-color: var(--el-color-primary-light-7);
    }

    .jet-avatar--color-custom {
        $avatar-color: v-bind(iconColor);
        $avatar-bg-color: v-bind(iconBgColor);

        color: $avatar-color;
        background-color: $avatar-bg-color;
    }

    .jet-avatar--pointer {
        cursor: pointer;
    }
</style>

<script setup lang="ts">
    import {ONBOARDING_TASK_CODENAME} from '@jetCommon/constants/onboarding';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding';

    import ListItem from '@jetCommon/components/ListItem.vue';
    import OnboardingTaskFileItem from '@/features/onboarding/components/tasks/base/OnboardingTaskFileItem.vue';

    const {previousLulMonthStr} = useOnboarding();
</script>

<template>
    <OnboardingTaskFileItem
        :description="`Carica lo zip dei cedolini di ${previousLulMonthStr} appena sono disponibili.`"
        confirm-button-text="Carica zip"
        :modal-title="`LUL, F24, Prospetti contabili di ${previousLulMonthStr}`"
        :codename="ONBOARDING_TASK_CODENAME.PREVIOUS_LUL"
        :title="`LUL, F24, Prospetti contabili di ${previousLulMonthStr}`">
        <template #dialogUploadBodyPre>
            <ListItem icon-name="files">
                Carica lo zip con tutti i cedolini di {{ previousLulMonthStr }} appena sono disponibili.
            </ListItem>
        </template>
    </OnboardingTaskFileItem>
</template>

import CompanyOnboardingTaskCommon from '@jetCommon/api/company-onboarding-task';

export default class CompanyVideoTutorialApi extends CompanyOnboardingTaskCommon {
    static resourceName = 'company_video_tutorials';

    markAsCompleted(id) {
        return this.apiPost(`${id}/mark_as_completed/`);
    }

    markAsOngoing(id) {
        return this.apiPost(`${id}/mark_as_ongoing/`);
    }
}

<script setup lang="ts">
    import {useClipboard} from '@vueuse/core';

    import JetButton from '@jetDS/components/JetButton.vue';
    import JetFakeInput from '@jetDS/components/JetFakeInput.vue';

    const props = defineProps({
        label: {type: String, default: ''},
        value: {type: String, required: true},
        extraInputClass: {type: String, default: ''},
    });

    const {copy} = useClipboard();
    const {$jetMessage} = inject('jet');

    function copyToClipboard() {
        copy(props.value);
        $jetMessage.info('Copiato negli appunti');
    }
</script>

<template>
    <JetFakeInput :label="label" :value="value" :input-class="`p-1 pl-4 pr-4 base medium ${extraInputClass}`">
        <template #prefix>
            <slot name="prefix" />
        </template>
        <template #suffix>
            <JetButton text class="CopyToClipboardText_CopyButton" icon-name="copy" @click.stop="copyToClipboard()" />
        </template>
    </JetFakeInput>
</template>

<script setup lang="ts">
    import JetSelect from '@jetDS/components/JetSelect.vue';

    const {$api} = inject('jet');

    const options = ref([]);

    async function getOptions() {
        options.value =
            (await $api.locations.getCountries().catch($api.end)).data?.map(country => ({
                value: country.code,
                display_name: country.value,
            })) ?? [];
    }

    getOptions();
</script>

<template>
    <JetSelect filterable :options="options" />
</template>

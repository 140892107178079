import {ApiBase} from '@jetCommon/api/base';

export default class PayslipExtrasApi extends ApiBase {
    static resourceName = 'payslip_extras';

    delete(payrollId, employeeId) {
        return this.apiPost(`delete/`, {
            payroll: payrollId,
            employee: employeeId,
        });
    }
}

import {ApiBase} from '@jetCommon/api/base';

export default class JetAsyncTaskApiCommon extends ApiBase {
    static resourceName = 'jet_async_tasks';

    get(taskId) {
        return this.apiGet(`${taskId}/`);
    }

    lastTaskForPerson(params) {
        return this.apiGet('last_task_for_person/', {params});
    }

    /**
     * Get the last async task execution for the current person.
     * @param {Object} params - The parameters for the request.
     * @returns {Promise<Object>} The response from the API.
     */
    getLastByFilters(params) {
        return this.apiGet('get_last_by_filters/', {params});
    }
}

<script setup lang="ts">
    import {storeToRefs} from 'pinia';

    import InputInfoLine from '@jetCommon/components/InputInfoLine.vue';

    const {$spaStore} = inject('jet');
    const {companySettings} = storeToRefs($spaStore);

    const activationDate = computed(() => companySettings.value.clockings_activation_date);
    const showSettings = computed(
        () => (activationDate.value && new Date(activationDate.value) > new Date()) || !activationDate.value,
    );
</script>

<template>
    <template v-if="showSettings">
        <h1 class="semibold primary md">Impostazioni timbrature</h1>
        <InputInfoLine
            :model-value="activationDate"
            input-type="date"
            readonly
            class="JetInfoLine"
            label="Data attivazione funzionalità"
            label-tooltip="Giorno dal quale vengono raccolte le timbrature e da cui si inizia a pagare la funzionalità"></InputInfoLine>
    </template>
</template>

<style scoped lang="scss">
    .ContainerHeader {
        display: flex;
        justify-content: space-between;
    }
    .JetInfoLine {
        border-top: var(--jet-info-line-border-bottom-width) solid var(--jet-border-color-base);
    }
</style>

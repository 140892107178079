<script setup lang="ts">
    import {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding';

    import DirectorsFeesForm from '@jetCommon/components/onboarding/DirectorsFeesForm.vue';
    import OnboardingTaskItem from '@/features/onboarding/components/tasks/base/OnboardingTaskItem.vue';

    const {fetchAll, taskByCodename} = useOnboarding();
    const task = computed(() => taskByCodename.value[ONBOARDING_TASK_CODENAME.DIRECTORS_FEES]);
    const taskIsToDo = computed(() => task.value.status === ONBOARDING_TASK_STATUS.TODO);

    const formRef = ref(null);

    const actions = computed(() => {
        return [
            {
                main: true,
                text: taskIsToDo.value ? 'Compila' : 'Visualizza',
                dialog: {
                    title: 'Compensi amministratori',
                    name: 'main',
                    confirm_button_text: 'Salva',
                    confirm_callback() {
                        formRef.value.submit();
                    },
                    show_confirm_button: taskIsToDo.value,
                },
            },
        ];
    });
</script>

<template>
    <OnboardingTaskItem
        :actions="actions"
        description="Comunicaci se gli amministratori ricevono un compenso tramite busta paga"
        :codename="ONBOARDING_TASK_CODENAME.DIRECTORS_FEES"
        title="Compensi amministratori">
        <template #dialogMainBody>
            <DirectorsFeesForm ref="formRef" :read-only="!taskIsToDo" :task="task" @submitted="fetchAll()" />
        </template>
    </OnboardingTaskItem>
</template>

<script setup lang="ts">
    import 'element-plus/es/components/upload/style/index';
    import {ElUpload} from 'element-plus';

    defineProps({
        fullWidth: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: null,
        },
    });

    const emit = defineEmits(['upload-in-progress']);

    const elUploadRef = ref();

    function submit() {
        elUploadRef.value.submit();
    }

    function handleRemove(file) {
        elUploadRef.value.handleRemove(file);
    }

    function clearFiles(file) {
        elUploadRef.value.clearFiles(file);
    }

    function onSuccess(response, uploadFile, uploadFiles) {
        if (uploadFiles.every(el => el.status === 'success')) {
            emit('upload-in-progress', false);
        }
    }

    function onChange(uploadFile, uploadFiles) {
        if (uploadFiles.some(el => el.status !== 'success')) {
            emit('upload-in-progress', true);
        }
    }

    defineExpose({submit, handleRemove, clearFiles});
</script>

<template>
    <div class="jet-upload-box" :class="{'jet-upload-box--full-screen': fullWidth}">
        <div v-if="title" class="jet-upload-box__title mb-2 medium base">
            {{ title }}
        </div>
        <ElUpload ref="elUploadRef" with-credentials v-bind="$attrs" :on-success="onSuccess" :on-change="onChange">
            <!--
                Expose all slots of the third-party component:
                https://mokkapps.de/vue-tips/expose-slots-from-a-child-component/
            -->
            <template v-for="(_, name) in $slots" #[name]="slotProps" :key="name">
                <slot :name="name" v-bind="slotProps || {}" />
            </template>
        </ElUpload>
    </div>
</template>

<style scoped lang="scss">
    // Reset to allow full width button
    :deep(.el-upload) {
        display: flex;
        justify-content: flex-start;
    }

    :deep(.el-upload-list__item) {
        margin-bottom: var(--jet-sp-2);
        background-color: var(--el-fill-color-light);
    }

    :deep(.el-upload-list__item-name) {
        color: var(--el-text-color-primary);
        font-weight: var(--jet-font-weight-semibold);
        font-size: var(--el-font-size-base);
        line-height: var(--jet-line-height-sm);
    }

    :deep(.el-upload-list__item-status-label > i) {
        display: none;
    }

    :deep(.el-icon.el-icon--close) {
        display: inline-flex;
    }

    .jet-upload-box__title {
        color: var(--el-text-color-regular);
    }

    .jet-upload-box--full-screen {
        width: 100%;

        :deep(.el-upload-dragger) {
            width: 100%;
        }
    }
</style>

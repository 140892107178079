<script setup lang="ts">
    import JetButton from '@jetDS/components/JetButton.vue';
    import JetIcon from '@jetDS/components/JetIcon.vue';
    import JetTooltip from '@jetDS/components/JetTooltip.vue';

    const props = defineProps({
        disabled: {
            type: Boolean,
            default: false,
        },
        editMode: {
            type: Boolean,
            default: false,
        },
        label: {
            type: String,
            default: '',
        },
        labelTooltip: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        actionLabel: {
            type: String,
            default: 'Modifica',
        },
        active: {
            type: Boolean,
            default: false,
        },
        disableHoverEffect: {
            type: Boolean,
            default: false,
        },
    });

    const emit = defineEmits(['update:editMode']);

    const internalEditMode = ref(props.editMode);
    watch(
        () => props.editMode,
        () => {
            internalEditMode.value = props.editMode;
        },
    );
    watch(internalEditMode, () => {
        emit('update:editMode', internalEditMode.value);
    });
</script>

<template>
    <div class="jet-info-line p-4" :class="{active, 'enable-hover': !disableHoverEffect}">
        <div class="jet-info-line__content-wrapper">
            <div class="jet-info-line__label-wrapper mr-6">
                <span class="jet-info-line__label">
                    <JetTooltip v-if="$slots.tooltipContent || labelTooltip" placement="bottom-start">
                        <template #content>
                            <slot name="tooltipContent">
                                {{ labelTooltip }}
                            </slot>
                        </template>
                        <JetIcon tabindex="-1" class="mr-1" name="info-circle" icon-style="regular" />
                    </JetTooltip>
                    <slot name="lineLabel">
                        <span class="base medium">{{ label }}</span>
                    </slot>
                </span>
                <span class="jet-info-line__description mt-1">
                    <slot name="lineDescription">
                        <span v-if="description" class="base light">{{ description }}</span>
                    </slot>
                </span>
            </div>

            <div v-show="!internalEditMode" class="jet-info-line__display-wrapper medium base">
                <div class="jet-info-line__display-value">
                    <slot />
                </div>
                <div
                    v-if="!internalEditMode && actionLabel"
                    class="jet-info-line__action-wrapper"
                    :class="{'jet-info-line__action-wrapper--edit-mode': editMode}">
                    <JetButton
                        :disabled="disabled"
                        text
                        class="jet-info-line__action bold base"
                        @click="internalEditMode = true">
                        {{ actionLabel }}
                    </JetButton>
                </div>
                <slot name="additionalActions" />
            </div>

            <div v-if="internalEditMode" class="jet-info-line__edit-form">
                <slot name="lineEdit" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use '@jetDS/scss/_media-queries.scss' as *;

    .jet-info-line {
        border-bottom: var(--jet-info-line-border-bottom-width) solid var(--el-border-color);
        background-color: transparent;
        transition: background-color 300ms;

        &.enable-hover:hover,
        &.active {
            background-color: var(--el-fill-color-light);
            transition: background-color 300ms;
        }

        &__content-wrapper {
            display: flex;
            flex-direction: column;
            align-items: stretch;

            @include media-query('md-and-up') {
                flex-direction: row;
                align-items: center;
            }

            &--multiline {
                align-items: baseline;
            }
        }

        &__label-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            flex-grow: 1;
            flex-basis: 35%;
            color: var(--el-text-color-secondary);
        }

        &__description {
            color: var(--jet-text-color-placeholder);
        }

        &__display-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-grow: 1;
            flex-basis: 65%;

            &--multiline {
                align-items: baseline;
            }
        }

        &__display-value {
            flex-grow: 1;
        }

        &__edit-form {
            flex-basis: 65%;
        }

        &__action-wrapper {
            &--edit-mode {
                flex-basis: 0;
            }
        }
    }
</style>

<script setup lang="ts">
    import {ibanRules, requiredFieldRule} from '@jetCommon/helpers/validators';

    import JetCallout from '@jetDS/components/JetCallout.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInput from '@jetDS/components/JetInput.vue';
    import JetRadio from '@jetDS/components/JetRadio.vue';
    import JetRadioGroup from '@jetDS/components/JetRadioGroup.vue';

    const props = defineProps({
        iban: {
            type: String,
            required: true,
        },
        readOnly: {
            type: Boolean,
            default: false,
        },
        task: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['submitted']);

    const {$api, $jetMessage} = inject('jet');
    const formRef = ref(null);

    const formData = reactive({
        iban: props.task.submitted_data?.iban || props.iban || null,
        should_jet_handle_f24: props.task.submitted_data?.should_jet_handle_f24,
    });
    const formRules = computed(() => ({
        iban: [...(formData.should_jet_handle_f24 === true ? [requiredFieldRule, ibanRules] : [])],
        should_jet_handle_f24: [requiredFieldRule],
    }));

    async function submit() {
        if (props.readOnly) {
            $jetMessage.warning('Non puoi modificare i dati in questo momento.');
            return;
        }

        const formEl = formRef.value.getElFormRef();
        const valid = await formEl.validate().catch(() => false);
        if (valid) {
            $api.companyOnboardingTasks
                .submitData(props.task.id, {
                    ...formData,
                })
                .then(() => emit('submitted'))
                .catch($api.end);
        }
    }

    defineExpose({
        formData,
        submit,
    });
</script>

<template>
    <JetForm ref="formRef" :rules="formRules" :model="formData">
        <p class="ExtraMonthlyPayForm_F24Label mb-1">
            Vuoi che gestiamo noi l'F24 da pagare il 16 del mese successivo a ciascun ciclo paghe?
        </p>
        <p class="mb-1 xs secondary medium">In alternativa potrai continuare a gestirlo tu o il tuo commercialista</p>
        <JetFormItem prop="should_jet_handle_f24">
            <JetRadioGroup v-model="formData.should_jet_handle_f24" :disabled="readOnly" vertical>
                <JetRadio :label="true">Sì, gestitelo voi</JetRadio>
                <JetRadio :label="false">No, continuo a gestirlo per conto mio</JetRadio>
            </JetRadioGroup>
        </JetFormItem>

        <JetCallout v-if="formData.should_jet_handle_f24 === false" type="info" plain>
            In questo caso Jet HR produrrà solamente il modello F24 in PDF.
        </JetCallout>

        <template v-if="formData.should_jet_handle_f24 === true">
            <JetFormItem prop="iban" label="IBAN">
                <JetInput v-model="formData.iban" :disabled="readOnly" icon-name="bank" />
            </JetFormItem>
            <JetCallout type="info" plain>
                L'IBAN per l'F24 è diverso da quello che ci hai comunicato per pagare Jet HR? Comunicaci qui quello
                corretto
            </JetCallout>
        </template>

        <JetCallout class="mt-4" type="warning" plain>
            Una volta salvati questi campi non sarà più possibile modificarli. Se hai fatto un errore contatta
            l'assistenza.
        </JetCallout>
    </JetForm>
</template>

<style lang="scss" scoped>
    .ExtraMonthlyPayForm {
        &_F24Label {
            color: var(--jet-color-primary-base);
            font-size: var(--jet-font-size-base);
            font-weight: var(--jet-font-weight-regular-medium);
            line-height: var(--jet-line-height-xs);
        }
    }
</style>

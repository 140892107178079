<script setup lang="ts">
    import {useCurrencyInput} from 'vue-currency-input';

    import JetIcon from '@jetDS/components/JetIcon.vue';
    import JetInput from '@jetDS/components/JetInput.vue';

    const props = defineProps({
        modelValue: {type: [Number, String], default: null}, // needed to override prop type and default
        size: {type: String, default: 'large'},
        precision: {type: Number, default: 2},
    });

    const euroIconComponent = h(JetIcon, {
        name: 'euro-sign',
        iconStyle: 'regular',
    });

    const {inputRef, formattedValue, setValue} = useCurrencyInput({
        currency: 'EUR',
        currencyDisplay: 'hidden',
        locale: 'it-IT',
        precision: props.precision,
    });

    watch(
        () => props.modelValue,
        value => {
            setValue(value);
        },
    );
</script>

<template>
    <JetInput ref="inputRef" v-model="formattedValue" :size="size" type="text" :suffix-icon="euroIconComponent" />
</template>

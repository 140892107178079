<script setup lang="ts">
    import JetIcon from './JetIcon.vue';

    const props = defineProps({
        status: {
            type: String,
            default: 'TODO',
            validator: value => ['TODO', 'PROGRESS', 'DONE'].includes(value),
        },
    });

    const iconName = computed(() => {
        return (
            {
                PROGRESS: 'circle-ellipsis',
                DONE: 'circle-check',
            }[props.status] || 'circle'
        );
    });
</script>

<template>
    <JetIcon
        :class="{
            'JetTaskIcon-todo': !['PROGRESS', 'DONE'].includes(props.status),
            'JetTaskIcon-progress': props.status === 'PROGRESS',
            'JetTaskIcon-done': props.status === 'DONE',
        }"
        :name="iconName"
        icon-style="regular"
        size="xl" />
</template>

<style scoped lang="scss">
    .JetTaskIcon {
        &-todo {
            color: var(--jet-color-info-base);
        }
        &-progress {
            color: var(--jet-color-info-base);
        }
        &-done {
            color: var(--jet-color-success-base);
        }
    }
</style>

<script setup lang="ts">
    import CountrySelect from '@jetCommon/components/inputs/CountrySelect.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInput from '@jetDS/components/JetInput.vue';
    import MunicipalitySelect from '@jetCommon/components/inputs/MunicipalitySelect.vue';
    import ProvinceSelect from '@jetCommon/components/inputs/ProvinceSelect.vue';

    const props = defineProps({
        disabled: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: () => {
                return {
                    street: '',
                    country: '',
                    province: '',
                    municipality: '',
                    postcode: '',
                };
            },
        },
        propsPrefix: {
            type: String,
            default: null,
        },
        modelValue: {
            type: Object,
            default: () => null,
        },
    });

    const emit = defineEmits(['update:modelValue']);

    function getFormItemProp(name) {
        return props.propsPrefix === null ? name : `${props.propsPrefix}.${name}`;
    }

    function getFormItemError(name) {
        return props.errors[name] ?? '';
    }

    const computedValue = computed(() => {
        if (!props.modelValue) {
            return {};
        }
        return props.modelValue;
    });

    function updateModelValue(key, newValue) {
        emit('update:modelValue', {
            ...props.modelValue,
            [key]: (newValue || '').trim() === '' ? null : newValue.trim(),
        });
    }
</script>

<template>
    <div class="AddressRow">
        <div class="AddressRow_Col">
            <JetFormItem
                label="Via e numero civico"
                :prop="getFormItemProp('street')"
                :error="getFormItemError('street')">
                <JetInput
                    type="text"
                    :disabled="disabled"
                    :model-value="computedValue.street"
                    @input="newValue => updateModelValue('street', newValue)" />
            </JetFormItem>
        </div>
    </div>
    <div class="AddressRow">
        <div class="AddressRow_Col">
            <JetFormItem label="Nazione" :prop="getFormItemProp('country')" :error="getFormItemError('country')">
                <CountrySelect
                    full-width
                    :disabled="disabled"
                    :model-value="computedValue.country || null"
                    @update:model-value="newValue => updateModelValue('country', newValue)" />
            </JetFormItem>
        </div>
        <div class="AddressRow_Col">
            <JetFormItem label="Provincia" :prop="getFormItemProp('province')" :error="getFormItemError('province')">
                <ProvinceSelect
                    full-width
                    :disabled="disabled"
                    :model-value="computedValue.province || null"
                    :country="computedValue.country || null"
                    @update:model-value="newValue => updateModelValue('province', newValue)" />
            </JetFormItem>
        </div>
    </div>
    <div class="AddressRow">
        <div class="AddressRow_Col">
            <JetFormItem
                label="Comune"
                :prop="getFormItemProp('municipality')"
                :error="getFormItemError('municipality')">
                <MunicipalitySelect
                    full-width
                    :disabled="disabled"
                    :country="computedValue.country || null"
                    :province="computedValue.province || null"
                    :model-value="computedValue.municipality || null"
                    @update:model-value="newValue => updateModelValue('municipality', newValue)" />
            </JetFormItem>
        </div>
        <div class="AddressRow_Col">
            <JetFormItem label="CAP" :prop="getFormItemProp('postcode')" :error="getFormItemError('postcode')">
                <JetInput
                    :type="computedValue.country === 'IT' ? 'number' : 'text'"
                    :disabled="disabled"
                    :model-value="computedValue.postcode"
                    @input="newValue => updateModelValue('postcode', newValue)" />
            </JetFormItem>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .AddressRow {
        display: flex;
        gap: 16px;

        &_Col {
            flex: 1 1 50%;
        }
    }
</style>

<script setup lang="ts">
    import {ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding';

    import JetTaskItem from '@jetDS/components/JetTaskItem.vue';

    const props = defineProps({
        actions: {
            type: Array,
            default: () => [],
        },
        codename: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
        metaText: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
    });

    defineEmits(['action', 'dialog-confirm']);
    const {taskByCodename} = useOnboarding();
    const task = computed(() => taskByCodename.value[props.codename]);

    const jetTaskItemRef = ref(false);

    const jetTaskStatus = computed(() => {
        return (
            {
                [ONBOARDING_TASK_STATUS.UNDER_APPROVAL]: 'PROGRESS',
                [ONBOARDING_TASK_STATUS.DONE]: 'DONE',
            }[task.value.status] || 'TODO'
        );
    });

    const parsedMetaText = computed(() => {
        if (props.metaText) {
            return props.metaText;
        }
        return (
            {
                [ONBOARDING_TASK_STATUS.TO_SET]: 'Disponibile a breve',
                [ONBOARDING_TASK_STATUS.UNDER_APPROVAL]: 'In approvazione',
            }[task.value.status] || ''
        );
    });

    function doMainAction() {
        jetTaskItemRef.value.onAction(props.actions.find(action => action.main));
    }
</script>

<template>
    <JetTaskItem
        ref="jetTaskItemRef"
        :actions="actions"
        :description="task.status !== ONBOARDING_TASK_STATUS.DONE ? description : ''"
        :meta-text="parsedMetaText"
        :status="jetTaskStatus"
        :title="title"
        @action="$emit('action', $event)"
        @click="doMainAction()"
        @dialog-confirm="$emit('dialog-confirm', $event)">
        <!--
          Expose all slots of the third-party component:
          https://mokkapps.de/vue-tips/expose-slots-from-a-child-component/
        -->
        <template v-for="(_, name) in $slots" #[name]="slotProps">
            <slot :name="name" v-bind="slotProps || {}" />
        </template>
    </JetTaskItem>
</template>

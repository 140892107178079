<script setup lang="ts">
    import 'element-plus/es/components/table-column/style/index';
    import {ElTableColumn} from 'element-plus';
    import {useDesignSystemTools} from '@jetCommon/composables/design-system-tools';

    import JetIcon from './JetIcon.vue';
    import JetSkeleton from '@jetDS/components/JetSkeleton.vue';
    import JetTooltip from '@jetDS/components/JetTooltip.vue';

    const props = defineProps({
        className: {
            type: String,
            default: '',
        },
        fixed: {
            type: [Boolean, String],
            default: false,
        },
        forceFixed: {
            type: Boolean,
            default: false,
        },
        sortingFields: {
            type: Array,
            default: () => null,
        },
        sortable: {
            type: Boolean,
            default: false,
        },
        colAlign: {
            type: String,
            default: 'left',
        },
        property: {
            type: String,
            default: null,
        },
        tooltip: {
            type: String,
            default: '',
        },
        skeletonType: {
            type: String,
            default: 'text',
        },
        tooltipProps: {
            type: Object,
            default: () => ({}),
        },
    });

    const slots = useSlots();

    const {tableColumnsUnfixed} = useDesignSystemTools();

    const getElementAlign = computed(() => {
        return {
            left: 'left',
            right: 'right',
            center: 'center',
        }[props.colAlign];
    });

    const classes = computed(() => {
        return [
            'jet-table-col',
            ...(props.className ? [props.className] : []),
            ...(props.property ? [`jet-table-col--${props.property}`] : []),
        ].join(' ');
    });
</script>

<template>
    <ElTableColumn
        :class-name="classes"
        :fixed="forceFixed || (tableColumnsUnfixed ? false : fixed)"
        :header-align="getElementAlign"
        :align="getElementAlign"
        :property="property"
        :sort-by="sortingFields"
        :sort-orders="['ascending', 'descending']"
        :sortable="sortable ? 'custom' : false">
        <template #header="{column, $index}">
            <div
                class="jet-table-col__header-wrapper"
                :class="{
                    'jet-table-col__header-wrapper--justify-left': getElementAlign === 'left',
                    'jet-table-col__header-wrapper--justify-right': getElementAlign === 'right',
                    'jet-table-col__header-wrapper--justify-center': getElementAlign === 'center',
                }">
                <span
                    class="jet-table-col__sort-icons"
                    :class="{'ml-1': getElementAlign === 'left', 'mr-1': getElementAlign === 'right'}">
                    <template v-if="sortable">
                        <JetIcon
                            v-if="column.order === 'ascending'"
                            name="arrow-down"
                            icon-style="regular"
                            class="jet-table-col__ascending-icon" />
                        <JetIcon
                            v-else-if="column.order === 'descending'"
                            name="arrow-up"
                            icon-style="regular"
                            class="jet-table-col__descending-icon" />
                        <JetIcon
                            v-else
                            name="arrow-down"
                            icon-style="regular"
                            class="jet-table-col__ascending-icon jet-table-col__ascending-icon--passive" />
                    </template>
                </span>

                <span class="jet-table-col__header-text">
                    <slot name="label">
                        {{ column.label }}
                    </slot>
                    <JetTooltip
                        v-if="$slots.tooltipContent || tooltip"
                        placement="bottom"
                        :content="$slots.tooltipContent ? undefined : tooltip"
                        v-bind="tooltipProps">
                        <template #content>
                            <slot name="tooltipContent" />
                        </template>
                        <JetIcon
                            v-if="!$slots.tooltipContent && tooltip"
                            class="jet-table-col__tooltip_icon ml-1"
                            name="info-circle"
                            icon-style="regular" />
                        <div v-else class="defaultTooltipAnchor" />
                    </JetTooltip>
                </span>
                <slot name="jetHeader" v-bind="{column, $index}" />
            </div>
        </template>

        <template v-if="slots.default" #default="{row, column, $index}">
            <!-- skip index -1 because el-table sucks -->
            <slot v-if="$index >= 0 && !row.isLoading" v-bind="{row, column, $index}" />

            <template v-if="row.isLoading">
                <JetSkeleton :kind="skeletonType" />
            </template>
        </template>
        <slot name="groupedColumns" />
    </ElTableColumn>
</template>

<style scoped lang="scss">
    .jet-table-col__header-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        .jet-table-col__header-text {
            line-height: var(--jet-line-height-xs);
        }

        .jet-table-col__ascending-icon,
        .jet-table-col__descending-icon {
            width: var(--jet-table-col-sort-icon-size);
            height: var(--jet-table-col-sort-icon-size);
            &--passive {
                color: var(--jet-text-color-disabled);
            }
        }

        &--justify-left {
            justify-content: flex-start;

            .jet-table-col__header-text {
                order: 1;
            }

            .jet-table-col__sort-icons {
                order: 2;
            }
        }

        &--justify-right {
            justify-content: flex-end;

            .jet-table-col__header-text {
                order: 2;
            }

            .jet-table-col__sort-icons {
                order: 1;
            }
        }

        &--justify-center {
            justify-content: center;
        }

        & + :deep(.caret-wrapper) {
            display: none;
        }
    }

    .defaultTooltipAnchor {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
</style>

<script setup lang="ts">
    import {DAILY_REFUND_SOURCE} from '@jetCommon/constants/daily-refund-constants';

    import JetLink from '@jetDS/components/JetLink.vue';

    const props = defineProps({
        showForeign: {
            type: [Boolean, null],
            default: null,
        },
        showItaly: {
            type: [Boolean, null],
            default: null,
        },
        source: {
            type: [String, null],
            default: null,
        },
    });

    const showItalyInfo = computed(
        () =>
            [DAILY_REFUND_SOURCE.TRIP_REGION, DAILY_REFUND_SOURCE.TRIP_ITALY].includes(props.source) || props.showItaly,
    );
    const showForeignInfo = computed(
        () =>
            [DAILY_REFUND_SOURCE.TRIP_UE, DAILY_REFUND_SOURCE.TRIP_EXTRA_UE].includes(props.source) ||
            props.showForeign,
    );
    const bothAreVisible = computed(() => showItalyInfo.value && showForeignInfo.value);
</script>

<template>
    <div>
        <div v-if="showItalyInfo" class="TripDailyRefundInfoAndLink_TextInfoBox">
            Per le trasferte in Italia, il limite esente è di 46,48 €. Se viene rimborsato a parte il vitto o
            l'alloggio, il limite si riduce a 30,99 €, mentre se entrambi sono rimborsati, si riduce a 15,49 €.
            <br />
            <br v-if="bothAreVisible" />
        </div>
        <div v-if="showForeignInfo" class="TripDailyRefundInfoAndLink_TextInfoBox">
            Per le trasferte all'estero, il limite esente è di 77,47 €. Se viene rimborsato a parte il vitto o
            l'alloggio, il limite si riduce a 51,65 €, mentre se entrambi sono rimborsati, si riduce a 25,82 €.
            <br />
            <br v-if="bothAreVisible" />
        </div>
        <JetLink
            class="TripDailyRefundInfoAndLink_Link"
            href="https://help.jethr.com/it/articles/9019589-trasferta-come-funziona-il-rimborso-spese"
            target="blank">
            Leggi l'articolo
        </JetLink>
    </div>
</template>

<style lang="scss" scoped>
    .TripDailyRefundInfoAndLink_Link {
        color: var(--jet-color-primary);
        font-weight: var(--jet-font-weight-bold);
        font-size: var(--jet-font-size-xs);
    }
</style>

<script setup lang="ts">
    import {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding';
    import {isoDateToIta} from '@jetCommon/helpers/date';
    import {openFile} from '@jetCommon/helpers/files';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding';

    import JetDatePicker from '@jetDS/components/JetDatePicker.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import OnboardingInfoBox from '@/features/onboarding/components/OnboardingInfoBox.vue';
    import OnboardingInfoBoxWrapper from '@/features/onboarding/components/OnboardingInfoBoxWrapper.vue';
    import OnboardingTaskItem from '@/features/onboarding/components/tasks/base/OnboardingTaskItem.vue';

    const {$api} = inject('jet');
    const {fetchAll, taskByCodename} = useOnboarding();
    const task = computed(() => taskByCodename.value[ONBOARDING_TASK_CODENAME.MAINTAIN_LUL_PEC_SENDING_DATE]);

    const formRef = ref(null);
    const formData = reactive({
        sending_date: null,
    });
    const formRules = reactive({
        sending_date: [{required: true, message: 'Campo obbligatorio', trigger: 'blur'}],
    });

    const subjectCopyText =
        'Adozione del sistema di gestione, tenuta e conservazione digitale del Libro Unico del lavoro';
    const contentCopyText =
        'Buongiorno,\n' +
        'si comunica in relazione alla conservazione del Libro Unico del Lavoro come da documento allegato.\n' +
        'Grazie,\n' +
        'Cordiali saluti';

    function downloadAttachment() {
        openFile(task.value.setup_data.pec_attachment_file_url);
    }

    const metaText = computed(() => {
        return task.value.status === ONBOARDING_TASK_STATUS.DONE && task.value?.submitted_data?.sending_date
            ? isoDateToIta(task.value.submitted_data.sending_date)
            : null;
    });

    const actions = computed(() => {
        const compileForm = {
            main: true,
            text: 'Comunica',
            dialog: {
                title: 'PEC per tenuta LUL',
                name: 'main',
                confirm_button_text: 'Comunica data',
                async confirm_callback() {
                    const valid = await formRef.value
                        .getElFormRef()
                        .validate()
                        .catch(() => false);
                    if (valid) {
                        $api.companyOnboardingTasks.submitData(task.value.id, formData).then(fetchAll).catch($api.end);
                    }
                },
            },
        };

        return task.value.status === ONBOARDING_TASK_STATUS.TODO ? [compileForm] : [];
    });
</script>

<template>
    <OnboardingTaskItem
        :actions="actions"
        :codename="ONBOARDING_TASK_CODENAME.MAINTAIN_LUL_PEC_SENDING_DATE"
        description="Comunicaci la data di invio della PEC. Ti forniamo tutto quello che ti serve per inviarla."
        :meta-text="metaText"
        title="PEC per tenuta LUL">
        <template #dialogMainBody>
            <p class="mb-4">
                Per conformarci alle normative vigenti, è necessario notificare all'Ispettorato del Lavoro la decisione
                di adottare un sistema di conservazione digitale per il Libro Unico del Lavoro. Questo passaggio è
                cruciale per assicurare che la nostra documentazione sia gestita in maniera conforme e accessibile in
                modo telematico.
            </p>
            <p class="mb-4">
                Invia una PEC all'indirizzo, oggetto, corpo della mail e allegato che trovi qui di seguito. Una volta
                inviata comunicaci la data dell'invio.
            </p>

            <OnboardingInfoBoxWrapper class="my-4">
                <OnboardingInfoBox title="Indirizzo PEC" :description="task.setup_data.pec" action-copy-description />
                <OnboardingInfoBox title="Oggetto" :description="subjectCopyText" action-copy-description />
                <OnboardingInfoBox title="Contenuto" :action-copy-description="contentCopyText">
                    <template #description>
                        Buongiorno,
                        <br />
                        si comunica in relazione alla conservazione del Libro Unico del Lavoro come da documento
                        allegato.
                        <br />
                        Grazie,
                        <br />
                        Cordiali saluti
                    </template>
                </OnboardingInfoBox>
                <OnboardingInfoBox
                    title="Allegato"
                    description="Scarica da qui il file da allegare nella PEC"
                    action-icon="download"
                    @action="downloadAttachment" />
            </OnboardingInfoBoxWrapper>

            <JetForm ref="formRef" :rules="formRules" :model="formData">
                <JetFormItem prop="sending_date" label="Una volta inviata inserisci la data dell'invio.">
                    <JetDatePicker v-model="formData.sending_date" full-width />
                </JetFormItem>
            </JetForm>
        </template>
    </OnboardingTaskItem>
</template>

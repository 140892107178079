import {ApiBase} from '@jetCommon/api/base';

export default class AdminApi extends ApiBase {
    static resourceName = 'admins';

    removeAvatar(adminId) {
        return this.apiDelete(`${adminId}/avatar/`);
    }

    retrieveNotificationSettings(adminId) {
        return this.retrieve(`${adminId}/notification_settings`);
    }

    sendInvitation(adminId) {
        return this.apiPost(`${adminId}/send_invitation/`);
    }

    uploadAvatar(adminId, data) {
        return this.apiPostForm(`${adminId}/avatar/`, data);
    }

    updateNotificationSettings(adminId, data) {
        return this.apiPost(`${adminId}/notification_settings/`, data);
    }
}

<script setup lang="ts">
    import {ONBOARDING_TASK_CODENAME, ONBOARDING_TASK_STATUS} from '@jetCommon/constants/onboarding';
    import {isoDateToIta} from '@jetCommon/helpers/date';
    import {useOnboarding} from '@/features/onboarding/composables/onboarding';

    import JetButton from '@jetDS/components/JetButton.vue';
    import JetDatePicker from '@jetDS/components/JetDatePicker.vue';
    import JetDivider from '@jetDS/components/JetDivider.vue';
    import JetFakeInputClipboard from '@jetDS/components/JetFakeInputClipboard.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import OnboardingTaskItem from '@/features/onboarding/components/tasks/base/OnboardingTaskItem.vue';

    const {$api} = inject('jet');
    const {fetchAll, taskByCodename} = useOnboarding();
    const task = computed(() => taskByCodename.value[ONBOARDING_TASK_CODENAME.CSO_730_MANDATE]);

    const formRef = ref(null);
    const formData = reactive({
        mandate_date: null,
    });

    const formRules = reactive({
        mandate_date: [{required: true, message: 'Campo obbligatorio', trigger: 'blur'}],
    });

    const metaText = computed(() => {
        return task.value.status === ONBOARDING_TASK_STATUS.DONE && task.value?.submitted_data?.mandate_date
            ? isoDateToIta(task.value.submitted_data.mandate_date)
            : null;
    });

    const actions = computed(() => {
        const compileForm = {
            main: true,
            text: 'Comunica',
            dialog: {
                title: 'Delega al cassetto fiscale',
                name: 'main',
                confirm_button_text: 'Comunica data',
                async confirm_callback() {
                    const valid = await formRef.value
                        .getElFormRef()
                        .validate()
                        .catch(() => false);
                    if (valid) {
                        $api.companyOnboardingTasks.submitData(task.value.id, formData).then(fetchAll).catch($api.end);
                    }
                },
            },
        };

        return task.value.status === ONBOARDING_TASK_STATUS.TODO ? [compileForm] : [];
    });

    function goToGuideLink() {
        window.open(
            'https://help.jethr.com/it/articles/9204096-come-delegare-il-consulente-del-lavoro-davanti-all-agenzia-delle-entrate',
            '_blank',
        );
    }
</script>

<template>
    <OnboardingTaskItem
        :actions="actions"
        class="onboardingtaskitem__cso730mandate"
        :codename="ONBOARDING_TASK_CODENAME.CSO_730_MANDATE"
        description="Comunicaci la data in cui hai delegato la gestione al consulente di Jet HR."
        :meta-text="metaText"
        title="Delega al cassetto fiscale">
        <template #dialogMainBody>
            <p class="mb-3">
                Il tuo consulente del lavoro del JetHR Partner Network potrà accedere al sito dell'Agenzia delle Entrate
                per recuperare le informazioni e i documenti rilevanti utili a svolgere gli adempimenti di legge.
            </p>
            <p class="mb-4">
                <JetFakeInputClipboard
                    label="Codice fiscale del tuo consulente, da inserire durante il processo di delega"
                    :value="task.setup_data.cdl_ssn" />
            </p>
            <p class="mb-3">
                <JetButton
                    icon-style="regular"
                    icon-name="book"
                    icon-position="left"
                    full-width
                    type="primary"
                    @click="goToGuideLink">
                    Premi qui e segui i passaggi della guida
                </JetButton>
            </p>

            <JetDivider />

            <div class="mt-6">
                <JetForm ref="formRef" :rules="formRules" :model="formData">
                    <JetFormItem prop="mandate_date" label="Una volta fatto comunicaci la data">
                        <JetDatePicker v-model="formData.mandate_date" full-width />
                    </JetFormItem>
                </JetForm>
            </div>
        </template>
    </OnboardingTaskItem>
</template>

import {SUBSCRIPTION_ADDONS_KIND} from '@jetCommon/constants/subscription-addon';
import {storeToRefs} from 'pinia';

const useContractorAddon = () => {
    const {$spaStore} = inject('jet');
    const {companyAddOns} = storeToRefs($spaStore);

    const contractorsAddonEnabled = computed(() => {
        return (
            companyAddOns.value?.find(addOn => addOn.kind === SUBSCRIPTION_ADDONS_KIND.CONTRACTORS)?.enabled_today ||
            false
        );
    });
    return {
        contractorsAddonEnabled,
    };
};

export {useContractorAddon};

import {ApiBase} from '@jetCommon/api/base';

export default class EmployeeHiringApi extends ApiBase {
    static resourceName = 'employee_hirings';

    start(employeeHiring) {
        return this.apiPost('start/', employeeHiring);
    }

    step(employeeHiringId, scope, employeeHiring) {
        return this.apiPatch(`${employeeHiringId}/step/?scope=${scope}`, employeeHiring);
    }

    getJobDescriptionSuggestions(jobTitle) {
        return this.apiPost('suggest_job_title_description/', {job_title: jobTitle});
    }

    getCCNLPrefilledParameters(employeeHiringId, params) {
        return this.apiGet(`${employeeHiringId}/required_parameters/`, {params});
    }
}

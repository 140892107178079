<script setup lang="ts">
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetRadio from '@jetDS/components/JetRadio.vue';
    import JetRadioGroup from '@jetDS/components/JetRadioGroup.vue';

    const props = defineProps({
        readOnly: {
            type: Boolean,
            default: false,
        },
        task: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['submitted']);

    const {$api, $jetMessage} = inject('jet');
    const formRef = ref(null);

    const formData = reactive({
        extra_monthly_pay: props.task.submitted_data?.extra_monthly_pay,
    });
    const requiredFieldRule = {required: true, message: 'Campo obbligatorio', trigger: 'blur'};
    const formRules = computed(() => ({
        extra_monthly_pay: [requiredFieldRule],
    }));

    const monthlyPaySetupKind = ref(null);
    const monthlyPayThirteenKind = ref(null);
    const monthlyPayFourteenKind = ref(null);

    function initMonthlyPayVars(extraMonthlyPayChoiceValue) {
        switch (extraMonthlyPayChoiceValue) {
            case 'THIRTEENTH_MONTHLY_SUPPLY':
                monthlyPaySetupKind.value = '13_ONLY';
                monthlyPayThirteenKind.value = 'MONTHLY';
                monthlyPayFourteenKind.value = null;
                break;

            case 'THIRTEENTH_STANDARD_SUPPLY':
                monthlyPaySetupKind.value = '13_ONLY';
                monthlyPayThirteenKind.value = 'STANDARD';
                monthlyPayFourteenKind.value = null;
                break;

            case 'THIRTEENTH_FOURTEENTH_STANDARD_SUPPLY':
                monthlyPaySetupKind.value = 'BOTH';
                monthlyPayThirteenKind.value = 'STANDARD';
                monthlyPayFourteenKind.value = 'STANDARD';
                break;

            case 'THIRTEENTH_FOURTEENTH_MONTHLY_SUPPLY':
                monthlyPaySetupKind.value = 'BOTH';
                monthlyPayThirteenKind.value = 'MONTHLY';
                monthlyPayFourteenKind.value = 'MONTHLY';
                break;

            case 'THIRTEENTH_STANDARD_FOURTEENTH_MONTHLY_SUPPLY':
                monthlyPaySetupKind.value = 'BOTH';
                monthlyPayThirteenKind.value = 'STANDARD';
                monthlyPayFourteenKind.value = 'MONTHLY';
                break;

            case 'FOURTEENTH_STANDARD_THIRTEENTH_MONTHLY_SUPPLY':
                monthlyPaySetupKind.value = 'BOTH';
                monthlyPayThirteenKind.value = 'MONTHLY';
                monthlyPayFourteenKind.value = 'STANDARD';
                break;
            case 'NONE':
                monthlyPaySetupKind.value = 'NONE';
                monthlyPayThirteenKind.value = null;
                monthlyPayFourteenKind.value = null;
        }
    }

    initMonthlyPayVars(formData.extra_monthly_pay);

    function resetMonthlyPayVars() {
        if (monthlyPaySetupKind.value === 'NONE') {
            monthlyPayThirteenKind.value = null;
            monthlyPayFourteenKind.value = null;
        } else if (monthlyPaySetupKind.value === '13_ONLY') {
            monthlyPayFourteenKind.value = null;
        }
    }

    function getExtraMonthlyPayChoiceValue() {
        if (monthlyPaySetupKind.value === 'NONE') {
            return 'NONE';
        }

        if (monthlyPaySetupKind.value === '13_ONLY') {
            return monthlyPayThirteenKind.value === 'MONTHLY'
                ? 'THIRTEENTH_MONTHLY_SUPPLY'
                : 'THIRTEENTH_STANDARD_SUPPLY';
        }

        if (monthlyPaySetupKind.value === 'BOTH') {
            if (monthlyPayThirteenKind.value === 'MONTHLY' && monthlyPayFourteenKind.value === 'MONTHLY') {
                return 'THIRTEENTH_FOURTEENTH_MONTHLY_SUPPLY';
            }
            if (monthlyPayThirteenKind.value === 'STANDARD' && monthlyPayFourteenKind.value === 'MONTHLY') {
                return 'THIRTEENTH_STANDARD_FOURTEENTH_MONTHLY_SUPPLY';
            }
            if (monthlyPayThirteenKind.value === 'MONTHLY' && monthlyPayFourteenKind.value === 'STANDARD') {
                return 'FOURTEENTH_STANDARD_THIRTEENTH_MONTHLY_SUPPLY';
            }
            if (monthlyPayThirteenKind.value === 'STANDARD' && monthlyPayFourteenKind.value === 'STANDARD') {
                return 'THIRTEENTH_FOURTEENTH_STANDARD_SUPPLY';
            }
        }
    }

    async function submit() {
        if (props.readOnly) {
            $jetMessage.warning('Non puoi modificare i dati in questo momento.');
            return;
        }

        const formEl = formRef.value.getElFormRef();
        const valid = await formEl.validate().catch(() => false);
        if (valid) {
            $api.companyOnboardingTasks
                .submitData(props.task.id, {
                    ...formData,
                    extra_monthly_pay: getExtraMonthlyPayChoiceValue(),
                })
                .then(() => emit('submitted'))
                .catch($api.end);
        }
    }

    defineExpose({
        formData,
        submit,
    });
</script>

<template>
    <JetForm ref="formRef" :rules="formRules" :model="formData">
        <JetFormItem label="Il tuo personale ha:">
            <JetRadioGroup v-model="monthlyPaySetupKind" vertical :disabled="readOnly" @change="resetMonthlyPayVars">
                <JetRadio label="13_ONLY">Solo la tredicesima</JetRadio>
                <JetRadio label="BOTH">La tredicesima e la quattordicesima</JetRadio>
                <JetRadio label="NONE">Nessuna delle due</JetRadio>
            </JetRadioGroup>
        </JetFormItem>

        <JetFormItem v-if="['13_ONLY', 'BOTH'].includes(monthlyPaySetupKind)" label="La tredicesima viene erogata">
            <JetRadioGroup v-model="monthlyPayThirteenKind" vertical :disabled="readOnly">
                <JetRadio label="MONTHLY">Mensilmente (ogni mese una piccola parte)</JetRadio>
                <JetRadio label="STANDARD">Tutta in una volta</JetRadio>
            </JetRadioGroup>
        </JetFormItem>

        <JetFormItem v-if="monthlyPaySetupKind === 'BOTH'" label="La quattordicesima viene erogata">
            <JetRadioGroup v-model="monthlyPayFourteenKind" vertical :disabled="readOnly">
                <JetRadio label="MONTHLY">Mensilmente (ogni mese una piccola parte)</JetRadio>
                <JetRadio label="STANDARD">Tutta in una volta</JetRadio>
            </JetRadioGroup>
        </JetFormItem>
    </JetForm>
</template>

<style lang="scss" scoped>
    .ExtraMonthlyPayForm {
        &_F24Label {
            color: var(--jet-color-primary-base);
            font-size: var(--jet-font-size-base);
            font-weight: var(--jet-font-weight-regular-medium);
            line-height: var(--jet-line-height-xs);
        }
    }
</style>

import {ApiBase} from '@jetCommon/api/base';

export default class PayrollEmployeeMealVoucherApi extends ApiBase {
    static resourceName = 'payroll_employee_meal_vouchers';

    getYearMonthChoices(params) {
        return this.apiGet('year_month_choices/', {params});
    }

    getCalendar(id) {
        return this.apiGet(`${id}/calendar/`);
    }

    getXlsxUrl(params) {
        return this.getApiUrlWithParams('download_xlsx/', params);
    }

    getSatispayCSVUrl(params) {
        return this.getApiUrlWithParams('download_csv_satispay/', params);
    }
}

<script setup lang="ts">
    import {EXPENSE_KIND_ICONS} from '@jetCommon/features/expense/constants/expense-constants';

    import JetAvatar from '@jetDS/components/JetAvatar.vue';

    const props = defineProps({
        avatarSize: {type: String, default: 'small'},
        kind: {type: String, required: true},
        kindDisplay: {type: String, default: null},
    });

    const kindData = computed(() => EXPENSE_KIND_ICONS?.[props.kind]);
</script>

<template>
    <div>
        <JetAvatar
            v-if="kindData"
            is-icon
            icon-style="regular"
            :icon-name="kindData.iconName"
            :icon-color="kindData.iconColor"
            :size="avatarSize" />
        <span v-if="kindDisplay" class="pl-2">{{ kindDisplay }}</span>
    </div>
</template>

import CompanyApiCommon from '@jetCommon/api/company';

export default class CompanyApi extends CompanyApiCommon {
    static resourceName = 'companies';

    // TODO: remove when report engine is in
    customExports(companyId, data) {
        return this.apiPost(`${companyId}/custom_exports/`, data);
    }

    getLastApprovedPayroll(companyId) {
        return this.apiGet(`${companyId}/last_approved_payroll/`);
    }

    getOnboarding(companyId, params = {}) {
        return this.apiGet(`${companyId}/onboarding/`, {params});
    }

    sendInvitations(companyId, data) {
        return this.apiPost(`${companyId}/send_invitations/`, data);
    }

    assignAccountingOffice(companyId, data) {
        return this.patch(`${companyId}/assign_accounting_office`, data);
    }

    getSafetyAndHealthPartners(companyId) {
        return this.apiGet(`${companyId}/safety_and_health_partners/`);
    }

    getVideoTutorial(companyId) {
        return this.apiGet(`${companyId}/video_tutorial/`, {
            params: {
                expand_video_tutorial: true,
            },
        });
    }

    uploadNewDVR(companyId, data) {
        return this.apiPost(`${companyId}/upload_new_dvr/`, data);
    }
}

import HiringApiCommon from '@jetCommon/api/hiring';

export default class HiringApi extends HiringApiCommon {
    duplicate(id) {
        return this.apiPost(`${id}/duplicate/`);
    }

    goBackToContract(id) {
        return this.apiPost(`${id}/go_back_to_contract/`);
    }

    nextSignerLink(employeeHiringId) {
        return this.apiGet(`${employeeHiringId}/next_signer_link/`);
    }

    markAsDeleted(id) {
        return this.apiPost(`${id}/mark_as_deleted/`);
    }
}

import {useJetMessage} from '@jetCommon/composables/jet-message';
import axios from 'axios';

const jetMessage = useJetMessage();

function getFileExtension(fileUrl) {
    if (typeof fileUrl !== 'string') {
        return null;
    }

    const [cleanUrl] = fileUrl.split('?');
    return cleanUrl.split('.').pop().trim().toLowerCase();
}

export async function forceFileDownload(publicUrl, fileName = '', extraConfig = {}) {
    try {
        const response = await axios.get(publicUrl, {responseType: 'blob', ...extraConfig});
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let downloadFileName = fileName;
        if (!downloadFileName) {
            downloadFileName = publicUrl.split('/').pop() || 'download';
        }
        link.setAttribute('download', downloadFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        jetMessage.error('Impossibile scaricare il file');
        throw error;
    }
}

export async function openFile(publicUrl, fileName = '', forceDownloadExtensions = ['xml']) {
    const fileExtension = getFileExtension(publicUrl);
    const requiresForceDownload = forceDownloadExtensions.includes(fileExtension);

    if (!requiresForceDownload) {
        window.open(publicUrl);
    } else {
        await forceFileDownload(publicUrl, fileName);
    }
}

export function formatFileSize(size) {
    if (size < 1024) {
        return `${Math.round(size)} B`;
    }
    if (size < 1024 * 1024) {
        return `${Math.round(size / 1024)} KB`;
    }
    return `${Math.round(size / 1024 / 1024)} MB`;
}

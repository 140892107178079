import {ApiBase} from '@jetCommon/api/base';

export default class HiringDocumentCommonApi extends ApiBase {
    static resourceName = 'hiring_documents';

    pdfPreviewUrl(id) {
        return this.getApiUrl(`${id}/pdf_preview/`, true);
    }

    duplicateLastCompanyLogo(companyId) {
        return this.apiPost('duplicate_last_used_logo/', {company: companyId});
    }
}

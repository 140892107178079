<script setup lang="ts">
    import {useStripe} from '@jetCommon/composables/use-stripe';

    import JetButton from '@jetDS/components/JetButton.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetServiceCard from '@jetDS/components/JetServiceCard.vue';

    const props = defineProps({
        companyId: {
            type: [String, Number],
            required: true,
        },
        showButton: {
            type: Boolean,
            default: false,
        },
    });
    const emit = defineEmits(['created']);

    const {$api, $jetMessage} = inject('jet');

    const isLoading = ref(false);

    let elements = null;
    let ibanElement = null;

    const {sharedStripe, sharedIsLoad} = useStripe();

    function initStripe() {
        elements = sharedStripe.value.elements();

        const style = {
            base: {
                color: '#11150A',
                fontSize: '14px',
                '::placeholder': {
                    color: '#aab7c4',
                },
                ':-webkit-autofill': {
                    color: '#11150A',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
                ':-webkit-autofill': {
                    color: '#fa755a',
                },
            },
        };
        ibanElement = elements.create('iban', {
            style,
            supportedCountries: ['SEPA'],
            placeholderCountry: 'IT',
        });
        ibanElement.mount('#iban-element');
    }

    watch(
        () => sharedIsLoad.value,
        () => {
            if (sharedIsLoad.value) {
                initStripe();
            }
        },
    );

    onMounted(() => {
        if (sharedIsLoad.value) {
            initStripe();
        }
    });

    async function completePaymentSetup(setupIntent) {
        return await $api.setup.completeCompanyPaymentSetup({
            setup_intent: setupIntent,
            company_id: props.companyId,
        });
    }

    async function confirmSepaDebitSetup(data) {
        return await sharedStripe.value.confirmSepaDebitSetup(data.client_secret, {
            payment_method: {
                sepa_debit: ibanElement,
                billing_details: {
                    name: data.company_name,
                    email: data.company_email,
                    address: {
                        city: data.company_address.municipality,
                        country: data.company_address.country,
                        line1: data.company_address.street,
                        postal_code: data.company_address.postcode,
                    },
                },
            },
        });
    }

    async function submit() {
        isLoading.value = true;
        try {
            const response = await $api.setup.initCompanyPaymentSetup(props.companyId);
            const {data} = response;

            const result = await confirmSepaDebitSetup(data);
            if (result.error) {
                $jetMessage.error(result.error?.message);
                throw new Error(result.error?.message ?? 'Errore durante la conferma del pagamento');
            }

            const paymentSetupInfo = await completePaymentSetup(result.setupIntent);

            if (props.showButton) {
                emit('created', paymentSetupInfo);
            }

            return paymentSetupInfo;
        } catch (error) {
            $api.end(error);
            throw error;
        } finally {
            isLoading.value = false;
        }
    }

    defineExpose({
        submit,
    });
</script>

<template>
    <JetServiceCard v-loading="isLoading">
        <p class="mb-3">Verrà usato per l'addebito diretto SEPA dei canoni mensili di Jet HR</p>
        <JetForm>
            <label for="iban-element">IBAN</label>
            <div id="iban-element" class="JetInputElement mt-2 mb-6">
                <!-- A Stripe Element will be inserted here. -->
            </div>
        </JetForm>

        <p class="mb-4">
            Inserendo l’IBAN autorizzi Stripe a mandare istruzioni alla tua banca per addebitare il tuo conto, e
            autorizzi la tua banca a seguire le istruzioni. Il tuo conto verrà addebitato mensilmente sulla base delle
            condizioni del contratto che hai firmato con Jet HR, a partire dalla data di primo payroll definita nel
            contratto. Riceverai notifiche 2 giorni prima di ogni addebito.
        </p>

        <template v-if="showButton" #ctaPrimary>
            <JetButton>Annulla</JetButton>
            <JetButton type="primary" @click="submit">Conferma</JetButton>
        </template>
    </JetServiceCard>
</template>

<style scoped lang="scss">
    .JetInputElement {
        border-color: var(--el-border-color-darker);
        border-style: var(--jet-input-border-style);
        border-width: var(--jet-input-border-width);
        border-radius: var(--jet-input-border-radius);
        padding: var(--jet-sp-2) var(--jet-sp-4);
    }

    .sepa-confirm-card {
        text-align: center;

        &__icon {
            color: var(--el-color-success);
            width: 64px;
            height: 64px;
        }
    }

    :deep(.jet-service-card__cta-primary) {
        display: flex;
        justify-content: flex-end;
    }
</style>

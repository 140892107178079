<script setup lang="ts">
    import AddressInput from '@jetCommon/components/inputs/AddressInput.vue';
    import JetDatePicker from '@jetDS/components/JetDatePicker.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInput from '@jetDS/components/JetInput.vue';

    const props = defineProps({
        disabled: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Object,
            default: () => {
                return {
                    name: '',
                    patron_saint: '',
                    additional_holiday: '',
                    address: {
                        street: '',
                        country: '',
                        province: '',
                        municipality: '',
                        postcode: '',
                    },
                };
            },
        },
        propsPrefix: {
            type: String,
            default: null,
        },
        modelValue: {
            type: Object,
            default: () => null,
        },
    });

    const emit = defineEmits(['update:modelValue']);

    function getFormItemProp(name) {
        return props.propsPrefix === null ? name : `${props.propsPrefix}.${name}`;
    }

    function getFormItemError(name) {
        return props.errors[name] ?? '';
    }

    const computedValue = computed(() => {
        if (!props.modelValue) {
            return {};
        }
        return props.modelValue;
    });

    function updateModelValue(key, newValue) {
        emit('update:modelValue', {
            ...props.modelValue,
            [key]: (newValue || '').trim() === '' ? null : newValue.trim(),
        });
    }

    const addressInputPropsPrefix = computed(() => (props.propsPrefix ? `${props.propsPrefix}.address` : 'address'));
</script>

<template>
    <JetFormItem label="Nome della sede" :prop="getFormItemProp('name')" :error="getFormItemError('name')">
        <JetInput
            :disabled="disabled"
            placeholder="Sede di Milano"
            full-width
            :model-value="computedValue.name || null"
            @update:model-value="newValue => updateModelValue('name', newValue)"></JetInput>
    </JetFormItem>
    <AddressInput
        v-model="computedValue.address"
        :disabled="disabled"
        :errors="errors.address"
        :props-prefix="addressInputPropsPrefix" />
    <JetFormItem
        label="Santo patrono"
        :prop="getFormItemProp('patron_saint')"
        :error="getFormItemError('patron_saint')">
        <JetDatePicker
            :disabled="disabled"
            placeholder="Scegli il giorno"
            format="DD/MM"
            full-width
            :model-value="computedValue.patron_saint || null"
            @update:model-value="newValue => updateModelValue('patron_saint', newValue)" />
    </JetFormItem>
    <JetFormItem
        label="Festività aggiuntiva (opzionale)"
        :prop="getFormItemProp('additional_holiday')"
        :error="getFormItemError('additional_holiday')">
        <JetDatePicker
            :disabled="disabled"
            placeholder="Scegli il giorno"
            format="DD/MM"
            full-width
            :model-value="computedValue.additional_holiday || null"
            @update:model-value="newValue => updateModelValue('additional_holiday', newValue)" />
    </JetFormItem>
</template>

import SafetyAndHealthApiCommon from '@jetCommon/api/safety-and-health';

export default class SafetyAndHealthApi extends SafetyAndHealthApiCommon {
    contactRequest(data) {
        return this.apiPost('contact_request/', data);
    }

    create(data) {
        return this.apiPost('', data);
    }

    update(safetyAndHealthId, data) {
        return this.apiPatch(`${safetyAndHealthId}/`, data);
    }

    deleteCompanyRole(id) {
        return this.apiDelete(`${id}/`);
    }

    getCounts(params) {
        return this.apiGet('counts/', {params});
    }

    getRoleChoices(params) {
        return this.apiGet('role_choices/', {params});
    }

    sendInvitationToEmployeesWithUnknownStatus(params) {
        return this.apiPost('send_invitation_to_employees_with_unknown_status/', {params});
    }

    sendInvitationToEmployeeWithUnknownStatus(id, data) {
        return this.apiPost(`${id}/send_invitation_to_employee_with_unknown_status/`, data);
    }

    resetData(id, data) {
        return this.apiPost(`${id}/reset_data/`, data);
    }

    updateKind(id, data) {
        return this.apiPost(`${id}/update_kind/`, data);
    }

    requestCertificate(id, data) {
        return this.apiPost(`${id}/request_certificate/`, data);
    }
}

import {ApiBase} from '@jetCommon/api/base';

export default class ContractorApiCommon extends ApiBase {
    static resourceName = 'contractors';

    /**
     * Send an invitation to a contractor
     * @param {number} contractorId - The ID of the contractor to send the invitation to
     * @param {Object} data - The data to send the invitation with
     * @returns {Promise<Object>} The response from the API
     */
    sendInvitation(contractorId, data = {}) {
        return this.apiPost(`${contractorId}/send_invitation/`, data);
    }

    /**
     * Check if a person works for the same company group
     * @param {{
     *   personal_email: string,
     *   company: number,
     * }} data - The data to check the person for
     * @returns {Promise<Object>} The response from the API
     */
    checkPersonWorksForSameCompanyGroup(data = {}) {
        return this.apiPost('check_person_works_for_same_company_group/', data);
    }

    /**
     * Create a contractor
     * @param {{
     *   first_name: string,
     *   last_name: string,
     *   personal_email: string,
     *   ssn: string,
     *   vat_number: string,
     *   professional_title: string,
     *   status: string,
     * }} data - The data to create the contractor with
     * @returns {Promise<Object>} The created contractor
     */
    createContractor(data) {
        return this.apiPost('create_full_employment/', data);
    }

    /**
     * Update a contractor
     * @param {number} contractorId - The ID of the contractor to update
     * @param {{
     *   first_name: string,
     *   last_name: string,
     *   personal_email: string,
     *   ssn: string,
     *   vat_number: string,
     *   professional_title: string,
     *   status: string,
     * }} data - The data to update the contractor with
     * @returns {Promise<Object>} The updated contractor
     */
    updateContractor(contractorId, data = {}) {
        return this.apiPatch(`${contractorId}/update_full_employment/`, data);
    }

    /**
     * Get the professional title choices
     * @param {Object} params - The query parameters
     * @returns {Promise<Object>} The professional title choices
     */
    professionalTitleChoices(params) {
        return this.apiGet('professional_title_choices/', {params});
    }

    /**
     * Get the collaboration status choices
     * @param {Object} params - The query parameters
     * @returns {Promise<Object>} The collaboration status choices
     */
    collaborationStatusChoices(params) {
        return this.apiGet('collaboration_status_choices/', {params});
    }
}

<script setup lang="ts">
    import 'element-plus/es/components/button/style/index';
    import {ElButton} from 'element-plus';

    import JetBadge from '@jetDS/components/JetBadge.vue';
    import JetIcon from '@jetDS/components/JetIcon.vue';

    const props = defineProps({
        type: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'small',
        },
        iconStyle: {
            type: String,
            default: 'regular',
        },
        iconName: {
            type: String,
            default: '',
        },
        iconPosition: {
            type: String,
            default: 'left',
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        showDot: {
            type: Boolean,
            default: false,
        },
        dotType: {
            type: String,
            default: null,
        },
        maxWidth: {
            type: [String, Number],
            default: 'unset',
        },
    });

    const hasTextContent = computed(() => {
        return useSlots().default;
    });
    const getElementPlusSize = computed(() => {
        return {
            large: 'large',
            medium: 'default',
            small: 'small',
        }[props.size];
    });
    const getElementPlusType = computed(() => {
        return {
            primary: 'primary',
            success: 'success',
            warning: 'warning',
            danger: 'danger',
            info: 'info',
        }[props.type];
    });

    const maxWidth = computed(() => {
        if (typeof props.maxWidth === 'string') {
            return props.maxWidth;
        }

        if (typeof props.maxWidth === 'number') {
            return `${props.maxWidth}px`;
        }

        return 'unset';
    });
</script>

<template>
    <ElButton
        :type="getElementPlusType"
        :size="getElementPlusSize"
        class="base jet-button"
        :class="{
            'jet-button--non-primary': type === '',
            'jet-button--icon-only': !hasTextContent,
            'jet-button--icon-only-small p-2': !hasTextContent && size === 'small',
            'jet-button--icon-only-medium': !hasTextContent && size === 'medium',
            'jet-button--icon-only-large': !hasTextContent && size === 'large',
            'jet-button--full-width': fullWidth,
        }">
        <JetIcon
            v-if="iconStyle && iconName && iconPosition === 'left'"
            :icon-style="iconStyle"
            :spin="iconName === 'loader'"
            :name="iconName"
            class="jet-button__icon--left" />
        <span v-if="hasTextContent" class="jet-button__text">
            <slot />
        </span>
        <JetIcon
            v-if="iconStyle && iconName && iconPosition === 'right'"
            :icon-style="iconStyle"
            :name="iconName"
            class="jet-button__icon--right" />
        <JetBadge v-if="showDot" class="jet-button__dot" is-dot :type="dotType" />
    </ElButton>
</template>

<style scoped lang="scss">
    .jet-button {
        font-family: inherit;
        height: unset;
        position: relative;
        max-width: v-bind(maxWidth);

        & + .jet-button {
            margin-left: var(--jet-sp-4);
        }

        &--full-width {
            width: 100%;
        }

        .jet-button__icon--left + .jet-button__text,
        .jet-button__text + .jet-button__icon--right {
            margin-left: var(--jet-sp-2);
        }

        &.is-text .jet-button__icon--left + .jet-button__text,
        &.is-text .jet-button__text + .jet-button__icon--right {
            margin-left: var(--jet-sp-2);
        }

        .jet-button__dot {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
        }

        svg,
        :deep(.el-icon),
        :deep(.el-icon svg) {
            width: var(--jet-button-icon-size);
            height: var(--jet-button-icon-size);
        }
    }

    .jet-button--non-primary.is-disabled {
        &,
        &:hover,
        &:focus,
        &:active {
            color: var(--el-text-color-disabled);
        }
    }

    .jet-button.el-button--primary {
        --el-button-disabled-bg-color: var(--el-color-primary-light-7);
        --el-button-disabled-border-color: var(--el-color-primary-light-7);

        &:active {
            color: var(--el-color-white);
        }
    }

    .jet-button.el-button--danger {
        --el-button-disabled-bg-color: var(--el-color-danger-light-7);
        --el-button-disabled-border-color: var(--el-color-danger-light-7);

        &:active {
            color: var(--el-color-white);
        }
    }

    .jet-button.el-button--primary.is-plain {
        --el-button-bg-color: var(--el-color-white);
    }

    .jet-button.el-button--danger.is-plain {
        --el-button-bg-color: var(--el-color-white);
    }

    .jet-button.el-button--primary.is-plain.is-disabled {
        &,
        &:hover,
        &:focus,
        &:active {
            background-color: var(--el-color-white);
            border-color: var(--el-border-color-light);
            color: var(--el-text-color-disabled);
        }
    }

    .jet-button.el-button--danger.is-plain.is-disabled {
        &,
        &:hover,
        &:focus,
        &:active {
            background-color: var(--el-color-white);
            border-color: var(--el-color-danger-light-5);
            color: var(--el-color-danger-light-7);
        }
    }

    .jet-button.is-text {
        color: var(--el-color-primary);
        padding-inline: var(--jet-sp-0);

        &.is-disabled {
            color: var(--el-text-color-placeholder);
        }

        &:not(.is-disabled):hover {
            background-color: transparent;
            color: var(--el-color-primary-light-3);
        }

        &:not(.is-disabled):active,
        &:not(.is-disabled):focus {
            background-color: transparent;
            color: var(--el-color-primary-dark-2);
        }
    }

    .jet-button.el-button--small,
    .jet-button.el-button--large {
        --el-button-size: unset;
    }

    .jet-button--icon-only {
        // Icon only buttons must have -2px to match standard buttons size when aside
        &-medium {
            padding: calc(var(--jet-sp-4) - 2px);
        }
        &-large {
            padding: calc(var(--jet-sp-4) - 2px);
        }
    }

    .jet-button.el-button.is-link {
        // Invert colors for link
        &.jet-button--icon-only {
            color: var(--el-button-hover-link-text-color);
            &:hover {
                color: var(--el-button-link-text-color);
            }
        }
        &:not(.jet-button--icon-only) {
            color: var(--el-button-link-text-color);
            &:hover {
                color: var(--el-button-hover-link-text-color);
            }
        }
    }
</style>

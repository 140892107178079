<script setup lang="ts">
    import JetCard from '@jetDS/components/JetCard.vue';

    defineProps({
        tagline: {type: String, default: null},
        title: {type: String, default: null},
    });
    const slots = useSlots();
</script>

<template>
    <JetCard class="jet-service-card">
        <span v-if="tagline" class="jet-service-card__tagline mb-2 xs bold">
            {{ tagline }}
        </span>

        <h2 class="mb-6 xl semibold">
            {{ title }}
        </h2>

        <div class="jet-service-card__intro">
            <slot name="intro" />
        </div>

        <div v-if="slots.default" class="mt-6 mx-0">
            <slot />
        </div>

        <div v-if="slots.ctaPrimary" class="jet-service-card__cta-primary">
            <slot name="ctaPrimary" />
        </div>

        <div v-if="slots.ctaSecondary" class="mt-2">
            <slot name="ctaSecondary" />
        </div>

        <div v-if="slots.footer" class="jet-service-card__footer pt-6">
            <slot name="footer" />
        </div>
    </JetCard>
</template>

<style lang="scss">
    .jet-service-card {
        &__tagline {
            color: var(--jet-text-color-secondary);
        }

        &__intro p {
            font-weight: var(--jet-font-weight-regular-medium);
            color: var(--jet-text-color-secondary);
        }

        &__footer {
            color: var(--jet-text-color-secondary);
        }
    }
</style>

<script setup lang="ts">
    import {addressFormRules} from '@jetCommon/helpers/validators';
    import {ref} from 'vue';
    import {storeToRefs} from 'pinia';

    import AddressInput from '@jetCommon/components/inputs/AddressInput.vue';
    import JetCallout from '@jetDS/components/JetCallout.vue';
    import JetDatePicker from '@jetDS/components/JetDatePicker.vue';
    import JetForm from '@jetDS/components/JetForm.vue';
    import JetFormItem from '@jetDS/components/JetFormItem.vue';
    import JetInfoLine from '@jetDS/components/JetInfoLine.vue';
    import JetInfoLineGroup from '@jetDS/components/JetInfoLineGroup.vue';
    import JetInput from '@jetDS/components/JetInput.vue';

    const props = defineProps({
        policy: {
            type: Object,
            required: true,
        },
    });

    const emit = defineEmits(['updated-form']);

    const {$authStore} = inject('jet');
    const {hasJetRole} = storeToRefs($authStore);

    const formRef = ref(null);
    const formData = reactive({
        name: props.policy.name,
        patron_saint: props.policy.patron_saint || null,
        additional_holiday: props.policy.additional_holiday || null,
        address: props.policy.address || {
            country: 'IT',
        },
    });

    watch(
        formData,
        () => {
            emit('updated-form', formData);
        },
        {deep: true},
    );

    const formRules = {
        name: [{required: true, message: 'Inserire il nome della policy', trigger: 'change'}],
        patron_saint: [{required: true, message: 'Inserire una data', trigger: 'change'}],
        additional_holiday: [{required: false, trigger: 'change'}],
        address: addressFormRules,
    };

    function isFormValid() {
        return formRef.value.getElFormRef().validate();
    }

    const disableForm = computed(() => !hasJetRole.value);

    defineExpose({
        isFormValid,
    });
</script>

<template>
    <JetInfoLineGroup>
        <JetCallout v-if="disableForm" plain type="warning" action-label="">
            <p>
                Puoi assegnare i dipendenti alla sede corretta. Se hai l'esigenza di creare o modificare modificare
                direttamente i dati della sede, contatta l'assistenza.
            </p>
        </JetCallout>

        <JetForm ref="formRef" :disabled="disableForm" :rules="formRules" :model="formData" label-position="top">
            <JetInfoLine label="Nome della sede" action-label="">
                <JetFormItem prop="name">
                    <JetInput v-model="formData.name" class="TextInput"></JetInput>
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine label="Indirizzo" action-label="">
                <AddressInput v-model="formData.address" props-prefix="address" />
            </JetInfoLine>
            <JetInfoLine label="Santo patrono" action-label="">
                <JetFormItem prop="patron_saint">
                    <JetDatePicker
                        v-model="formData.patron_saint"
                        placeholder="Scegli il giorno"
                        size="large"
                        format="DD/MM" />
                </JetFormItem>
            </JetInfoLine>
            <JetInfoLine label="Festività aggiuntiva" action-label="">
                <JetFormItem prop="additional_holiday">
                    <JetDatePicker
                        v-model="formData.additional_holiday"
                        placeholder="Scegli il giorno"
                        size="large"
                        format="DD/MM" />
                </JetFormItem>
            </JetInfoLine>
        </JetForm>
    </JetInfoLineGroup>
</template>

<style scoped lang="scss">
    .TextInput {
        max-width: 320px;
    }

    // Address
    .form-flex-block {
        display: flex;
        flex-wrap: nowrap;

        &__item-half {
            flex: 1 1 auto;

            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }
</style>

import {end} from '@jetCommon/api';
import AbsenceCalendarApiCommon from '@jetCommon/api/absence-calendar';
import AbsenceCalendarSettingsApi from '@/api/absence-calendar-settings';
import AbsenceRequestApi from '@/api/absence-request';
import AbsenceRequestKindSettingsApiCommon from '@jetCommon/api/absence-request-kind-settings';
import AccountantApiCommon from '@jetCommon/api/accountant';
import AciCarCostApiCommon from '@jetCommon/api/aci-car-cost';
import AdminApi from '@/api/admin';
import AgendaItemsApiCommon from '@/api/agenda-items';
import AuthApiCommon from '@jetCommon/api/auth';
import CCNLApiCommon from '@jetCommon/data-stores-api/ccnl';
import ClockingApi from '@/api/clocking';
import CompanyAbsencePoliciesApiCommon from '@jetCommon/api/company-absence-policies';
import CompanyAbsenceRequestKindApiCommon from '@jetCommon/api/company-absence-request-kind';
import CompanyApi from '@/api/company';
import CompanyBoardDocumentApiCommon from '@jetCommon/api/company-board-document';
import CompanyCCNLSettingsApiCommon from '@jetCommon/api/company-ccnl-settings';
import CompanyClockingPolicyApi from '@/api/company-clocking-policy';
import CompanyDocumentApiCommon from '@jetCommon/api/company-document';
import CompanyExpensePolicyApi from '@/api/company-expense-policy';
import CompanyHeadquartersPolicyApi from '@jetCommon/api/company-headquarters-policy';
import CompanyMealVoucherPoliciesApi from '@/api/company-meal-voucher-policies';
import CompanyOnboardingTaskApi from '@/api/company-onboarding-task';
import CompanyOnboardingTaskDocumentApi from '@/api/company-onboarding-task-document';
import CompanyPaymentApiCommon from '@jetCommon/api/company-payment';
import CompanyPayslipExtraBonusKindApiCommon from '@jetCommon/api/company-payslip-extra-bonus-kind';
import CompanySalaryPaymentSettingsApi from '@/api/company-salary-payment-settings';
import CompanySettingsApi from '@/api/company-settings';
import CompanyShiftPresetApiCommon from '@jetCommon/api/company-shift-preset';
import CompanyTripDailyRefundPolicyApi from '@/api/company-trip-daily-refund-policy';
import CompanyVideoTutorialApi from '@/api/company-video-tutorial';
import CompanyWorkingHoursApiCommon from '@jetCommon/api/company-working-hours';
import ContractVariationApi from '@/api/contract-variation';
import ContractVariationDocumentApi from '@/api/contract-variation-document';
import ContractorApi from '@/api/contractor';
import ContractorCollaborationApiCommon from '@jetCommon/api/contractor-collaboration';
import ContractorInvoiceApiCommon from '@jetCommon/api/contractor-invoice';
import ContractorInvoicesByPaymentPeriodApi from '@/api/contractor-invoices-by-payment-period';
import ContractorWorkingHoursApiCommon from '@jetCommon/api/contractor-working-hours';
import CustomizableDataTablesApiCommon from '@jetCommon/api/customizable-data-tables';
import EmployeeApi from '@/api/employee';
import EmployeeCustomFieldApi from '@/api/employee-custom-field';
import EmployeeDocumentApiCommon from '@jetCommon/api/employee-document';
import EmployeeHiringApi from '@/api/employee-hiring';
import EmployeeMonthlyReportApiCommon from '@jetCommon/api/employee-monthly-report';
import EmployeeShiftApiCommon from '@jetCommon/api/employee-shift';
import EmployeeWorkingHoursApiCommon from '@jetCommon/api/employee-working-hours';
import ExchangeRatesApiCommon from '@jetCommon/api/exchange-rates';
import ExpenseApiCommon from '@jetCommon/api/expense';
import HiringApi from '@/api/hiring';
import HiringAttachmentsApi from '@/api/hiring-attachments';
import HiringClausesApi from '@/api/hiring-clauses';
import HiringDocumentApi from '@/api/hiring-document';
import HolidaysApiCommon from '@jetCommon/api/holidays';
import JetAsyncTaskApiCommon from '@jetCommon/api/async-task';
import LocationApiCommon from '@jetCommon/api/location';
import ManagerApiCommon from '@jetCommon/api/manager';
import MonthlyReportApi from '@jetCommon/api/monthly-report';
import PayrollApiCommon from '@jetCommon/api/payroll';
import PayrollAttachmentsApiCommon from '@jetCommon/api/payroll-attachments';
import PayrollEmployeeMealVoucherApi from '@/api/payroll_employee_meal_voucher';
import PayrollProvisionalApi from '@/api/payroll-provisional';
import PayslipApi from '@jetCommon/api/payslip';
import PayslipExtrasApi from '@/api/payslip-extras';
import PersonAddressApiCommon from '@jetCommon/api/person-address';
import PersonApiCommon from '@jetCommon/api/person';
import RecurringPayslipExtrasApiCommon from '@jetCommon/api/recurring-payslip-extras';
import SafetyAndHealthApi from '@/api/safety-and-health';
import SetupApi from '@jetCommon/api/setup';
import SignatureRequestApi from '@/api/signature-request';
import UploadedFilesApiCommon from '@jetCommon/api/uploadedFiles';

export const api = {
    end,
    asyncTasks: new JetAsyncTaskApiCommon(),
    absenceCalendar: new AbsenceCalendarApiCommon(),
    absenceCalendarSettings: new AbsenceCalendarSettingsApi(),
    absenceRequests: new AbsenceRequestApi(),
    absenceRequestKindSettings: new AbsenceRequestKindSettingsApiCommon(),
    aciCarCosts: new AciCarCostApiCommon(),
    accountants: new AccountantApiCommon(),
    admins: new AdminApi(),
    agendaItems: new AgendaItemsApiCommon(),
    auth: new AuthApiCommon(),
    ccnl: new CCNLApiCommon(),
    companyAbsencePolicies: new CompanyAbsencePoliciesApiCommon(),
    companyAbsenceRequestKinds: new CompanyAbsenceRequestKindApiCommon(),
    companies: new CompanyApi(),
    companyMealVoucherPolicies: new CompanyMealVoucherPoliciesApi(),
    companySalaryPaymentSettings: new CompanySalaryPaymentSettingsApi(),
    companySettings: new CompanySettingsApi(),
    companyCCNLSettings: new CompanyCCNLSettingsApiCommon(),
    companyDocuments: new CompanyDocumentApiCommon(),
    companyBoardDocuments: new CompanyBoardDocumentApiCommon(),
    companyExpensePolicy: new CompanyExpensePolicyApi(),
    companyClockingPolicy: new CompanyClockingPolicyApi(),
    companyHeadquartersPolicy: new CompanyHeadquartersPolicyApi(),
    companyTripDailyRefundPolicy: new CompanyTripDailyRefundPolicyApi(),
    companyOnboardingTaskDocuments: new CompanyOnboardingTaskDocumentApi(),
    companyOnboardingTasks: new CompanyOnboardingTaskApi(),
    companyPayments: new CompanyPaymentApiCommon(),
    companyPayslipExtraBonusKinds: new CompanyPayslipExtraBonusKindApiCommon(),
    companyShiftPreset: new CompanyShiftPresetApiCommon(),
    companyVideoTutorials: new CompanyVideoTutorialApi(),
    companyWorkingHours: new CompanyWorkingHoursApiCommon(),
    contractors: new ContractorApi(),
    contractorInvoices: new ContractorInvoiceApiCommon(),
    contractorInvoicesByPaymentPeriod: new ContractorInvoicesByPaymentPeriodApi(),
    contractorCollaborations: new ContractorCollaborationApiCommon(),
    contractorWorkingHours: new ContractorWorkingHoursApiCommon(),
    contractVariations: new ContractVariationApi(),
    contractVariationDocuments: new ContractVariationDocumentApi(),
    clocking: new ClockingApi(),
    customizableDataTables: new CustomizableDataTablesApiCommon(),
    employeeCustomFields: new EmployeeCustomFieldApi(),
    employeeDocuments: new EmployeeDocumentApiCommon(),
    employeeMonthlyReports: new EmployeeMonthlyReportApiCommon(),
    employeeShifts: new EmployeeShiftApiCommon(),
    employees: new EmployeeApi(),
    employeeWorkingHours: new EmployeeWorkingHoursApiCommon(),
    exchangeRates: new ExchangeRatesApiCommon(),
    expenses: new ExpenseApiCommon(),
    hirings: new HiringApi(),
    hiringAttachments: new HiringAttachmentsApi(),
    hiringClauses: new HiringClausesApi(),
    hiringDocuments: new HiringDocumentApi(),
    holidays: new HolidaysApiCommon(),
    employeeHirings: new EmployeeHiringApi(),
    locations: new LocationApiCommon(),
    managers: new ManagerApiCommon(),
    monthlyReports: new MonthlyReportApi(),
    payrollAttachments: new PayrollAttachmentsApiCommon(),
    payrollEmployeeMealVouchers: new PayrollEmployeeMealVoucherApi(),
    payrollProvisional: new PayrollProvisionalApi(),
    payrolls: new PayrollApiCommon(),
    payslipExtras: new PayslipExtrasApi(),
    payslips: new PayslipApi(),
    personAddresses: new PersonAddressApiCommon(),
    persons: new PersonApiCommon(),
    recurringPayslipExtras: new RecurringPayslipExtrasApiCommon(),
    safetyAndHealth: new SafetyAndHealthApi(),
    setup: new SetupApi(),
    signatureRequests: new SignatureRequestApi(),
    uploadedFiles: new UploadedFilesApiCommon(),
};

import {ApiBase} from '@jetCommon/api/base';
import {dailyClockingSurrogateKey} from '@jetCommon/helpers/clocking';

export default class ClockingsApiCommon extends ApiBase {
    static resourceName = 'clockings';

    yearMonthChoices(companyId, includeNextMonth = false) {
        return this.apiGet('year_month_choices/', {
            params: {company: companyId, include_next_month: includeNextMonth},
        });
    }

    updateClockings(employeeId, companyId, date, clockings, note) {
        return this.apiPatch(`${dailyClockingSurrogateKey(employeeId, date)}/`, {company: companyId, clockings, note});
    }

    // This is for admins & managers
    ignoreAnomalies(companyId, date, employeeId, ignoreFlag) {
        return this.apiPost(`${dailyClockingSurrogateKey(employeeId, date)}/ignore_anomalies/`, {
            company: companyId,
            ignore: ignoreFlag,
        });
    }

    // This is for admins & managers
    workedTimeSummary(params, config = {}) {
        return this.list(params, config, 'worked_time_summary/');
    }

    stats(params) {
        return this.apiGet('stats/', {params});
    }
}

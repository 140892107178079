import EmployeeApiCommon from '@jetCommon/api/employee';

export default class EmployeeApi extends EmployeeApiCommon {
    managerReportChoices(params) {
        return this.apiGet('manager_reports_choices/', {params});
    }

    updatePersonalEmail(employeeId, data) {
        return this.apiPost(`${employeeId}/update_personal_email/`, data);
    }
    removeManagersFromEmployees(data) {
        return this.apiPost('remove_managers_from_employees/', data);
    }
}
